const defaultState = () => {
  return {
    user: null,
    isAuthenticated: false,
    isAdmin: false
  }
}

const state = defaultState()

const mutations = {
  LOGIN (state, auth) {
    state.user = auth.user
    state.isAuthenticated = true
    state.isAdmin = auth.isAdmin
  },
  LOGOUT (state) {
    state.user = null
    state.isAuthenticated = false
    state.isAdmin = false
  }
}

const actions = {
  LOGIN ({ commit }, auth) {
    commit('LOGIN', auth)
  },
  LOGOUT ({ commit }) {
    commit('LOGOUT')
  }
}

const getters = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
