import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins/api'

import axios from 'axios'
import VueAxios from 'vue-axios'
import _ from 'lodash'
import VueLodash from 'vue-lodash'
import moment from 'moment'
import VueMoment from 'vue-moment'
import Clipboard from 'v-clipboard'
import Snackbar from 'vuetify-snackbars'
import VideoBg from 'vue-videobg'

import { ValidationObserver, ValidationProvider, extend } from 'vee-validate/dist/vee-validate.full'

import JsonViewer from 'vue-json-viewer'

import { OktaAuth } from '@okta/okta-auth-js'
import OktaVue from '@okta/okta-vue'

const oktaAuth = new OktaAuth({
  issuer: process.env.OKTA_DOMAIN,
  clientId: process.env.OKTA_CLIENT_ID,
  redirectUri: `${window.location.origin}/callback`
})

Vue.use(OktaVue, { oktaAuth })

Vue.config.productionTip = false

Vue.use(VueAxios, axios)
Vue.use(VueLodash, { lodash: _ })
Vue.use(VueMoment, { moment })
Vue.use(Clipboard)
Vue.use(Snackbar)

Vue.component('video-bg', VideoBg)

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
extend(
  'date_format',
  {
    computesRequired: true,
    validate: (value, args) => {
      if (!value) return true
      return moment(value, args[0], true).isValid()
    },
    message: (value, args) => {
      return `Incorrect date format! Must be ${args[0]}`
    }
  }
)
extend(
  'date_before',
  {
    computesRequired: true,
    validate: (value, args) => {
      if (!value || !args[0]) return true
      return moment(value).isSameOrBefore(args[0])
    },
    message: (value, args) => {
      return `Incorrect date! Must be the same or before ${args[0]}`
    }
  }
)
extend(
  'date_after',
  {
    computesRequired: true,
    validate: (value, args) => {
      if (!value || !args[0]) return true
      return moment(value).isSameOrAfter(args[0])
    },
    message: (value, args) => {
      return `Incorrect date! Must be the same or after ${args[0]}`
    }
  }
)

Vue.use(JsonViewer)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
