import Vue from 'vue'
import axios from 'axios'

const USE_LOCALHOST = false
export const url = USE_LOCALHOST ? 'http://localhost:8080' : `https://api.unity-metrics.${process.env.CLUSTER}.ds.unity3d.com`

export const apiInstance = axios.create({
  baseURL: url
})

apiInstance.interceptors.request.use(
  async (request) => {
    const accessToken = await Vue.prototype.$auth.getAccessToken()
    request.headers = { Authorization: `Bearer ${accessToken}` }
    return request
  },
  (error) => {
    return Promise.reject(error)
  }
)

Vue.prototype.$api = apiInstance
